export const {
  REACT_APP_FIREBASE_API_KEY = "AIzaSyDPLWQhYX2ASec0RTsgiUhIydu92YVVgTQ",
  REACT_APP_AUTH_DOMAIN = "enwat-1425f.firebaseapp.com",
  REACT_APP_DATABASE_URL,
  REACT_APP_PROJECT_ID = "enwat-1425f",
  REACT_APP_FIREBASE_STORAGE_BUCKET = "enwat-1425f.appspot.com",
  REACT_APP_FIREBASE_MESSAGING_ID = "902674109534",
  REACT_APP_FIREBASE_APP_ID = "1:902674109534:web:c66a9d1407690ba4d610cf",
  REACT_APP_MEASUREMENT_ID,
} = process.env;
export const FIREBASE_IMAGE_REF = "contactimages";
