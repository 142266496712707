import React from "react";
import HomeUI from "../../layout/Home";

const ContactsContainer = () => {

  return (
    <HomeUI
    />
  );
};

export default ContactsContainer;
